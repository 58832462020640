import React from 'react'
import { Flex, Text } from '../../primitives'
import { PrimitiveDot } from 'styled-icons/octicons/PrimitiveDot'
import { ButtonPrimaryLight } from '../buttons/ButtonPrimaryLight'
import { Image } from '../Images/Image'
import { Check } from 'styled-icons/boxicons-regular/Check'

const Title = () => {
  return (
    <>
      <Text
        display={['none', 'none', 'block']}
        fontWeight={'600'}
        fontSize={'62px'}
        lineHeight={'60px'}
        color={'black'}>
        Boka en <br />
        inredare <br />
        <b>idag</b>
      </Text>
      <Text
        display={['block', 'block', 'none']}
        fontSize={['46px', '62px']}
        color={'black'}
        textAlign={'center'}>
        Boka en <br /> inredare <b>idag</b>
      </Text>
    </>
  )
}
const BulletList = ({ actionClick }) => {
  return (
    <>
      <Flex
        jusifyContent={'space-between'}
        pb={0}
        mt={[7, 5, 7]}
        flexDirection={['column', 'column', 'column']}>
        <Text
          fontSize={['18px', '25px']}
          color={'black'}
          textAlign={['center', 'center', 'start']}>
          <Check
            size='30px'
            style={{ marginBottom: '5px', marginRight: '5px' }}
          />
          Professionella designers
        </Text>
        <Text
          fontSize={['18px', '23px']}
          color={'black'}
          textAlign={['center', 'center', 'start']}>
          <Check
            size='30px'
            style={{ marginBottom: '5px', marginRight: '5px' }}
          />
          Personlig kontakt
        </Text>
        <Text
          fontSize={['18px', '23px']}
          color={'black'}
          textAlign={['center', 'center', 'start']}>
          <Check
            size='30px'
            style={{ marginBottom: '5px', marginRight: '5px' }}
          />
          Snabbt och enkelt
        </Text>
      </Flex>
      <Text mt={[4, 5, 6]} color={'black'} fontSize={['18px', '23px']}>
        <b>FRÅN 799 KR </b> / per rum
      </Text>
      <Flex mt={[4, 5, 6]}>
        <ButtonPrimaryLight onClick={actionClick}>KOM IGÅNG</ButtonPrimaryLight>
      </Flex>
    </>
  )
}

const Img = ({ bgSrc, width, height = 'initial' }) => {
  return (
    <Flex width={width} height={height}>
      <Image objectFit={'contain'} src={bgSrc} />
    </Flex>
  )
}

const Holder = ({ display, bg = 'transparent', children, ...props }) => {
  return (
    <Flex
      pl={['initial', 'initial', 100]}
      display={display}
      style={{ position: 'relative' }}
      height={['100vh', '100vh', '700px']}
      minHeight={['650px', '650px', 'initial']}
      jusifyContent={'center'}
      bg={bg}
      alignItems={'center'}
      {...props}>
      {children}
    </Flex>
  )
}

const Desktop = ({ actionClick, headerSrc }) => {
  return (
    <Holder display={['none', 'none', 'flex']} bg={'#ededed'}>
      <Flex
        pl={8}
        pr={8}
        width={'100%'}
        maxWidth={'1350px'}
        justifyContent={'center'}>
        <Flex flexDirection={'column'}>
          <Title />
          <BulletList actionClick={actionClick} />
        </Flex>
        <Flex flex={1} pl={50} pr={50} alignItems={'center'}>
          <Img bgSrc={headerSrc} width={'100%'} />
        </Flex>
      </Flex>
    </Holder>
  )
}

const Mobile = ({ actionClick, headerSrc }) => {
  return (
    <Holder
      pt={100}
      pb={50}
      display={['flex', 'flex', 'none']}
      bg={'#ededed'}
      flexDirection={'column'}>
      <Title />
      <Img bgSrc={headerSrc} width={'100%'} />
      <BulletList actionClick={actionClick} />
    </Holder>
  )
}

export const HeroFrontPage = ({ actionClick, headerSrc, headerSrcMobile }) => {
  return (
    <>
      <Desktop actionClick={actionClick} headerSrc={headerSrc} />
      <Mobile actionClick={actionClick} headerSrc={headerSrcMobile} />
    </>
  )
}
