import React from 'react'
import { Flex, Text } from '../../primitives'
import { Image } from '../Images/Image'

const Collab = ({ imgSrc }) => {
  return (
    <Flex
      flex={'none'}
      bg={'grey'}
      height={'100px'}
      jusifyContent={'center'}
      alignItems={'center'}
      style={{
        borderRadius: '50%'
      }}
      mt={2}
      mb={2}
      ml={3}
      mr={3}
      width={'100px'}>
      <Image
        objectFit={'contain'}
        width={'80px'}
        height={'80px'}
        src={imgSrc}
      />
    </Flex>
  )
}

export const Collaborators = ({ imgs }) => {
  return (
    <Flex
      pt={[4, 5, 6]}
      pb={[4, 5, 6]}
      flexDirection={'column'}
      alignItems={'center'}
      jusifyContent={'center'}>
      <Flex jusifyContent={'space-between'}>
        <Text fontSize={[5, 5, 5]} mb={6} fontWeight={600}>
          Samarbetspartners
        </Text>

        {/*
        <Flex display={['flex', 'flex', 'none']}>
          <ArrowRight2
            style={{ marginTop: '5px', marginLeft: '5px' }}
            size={'20px'}></ArrowRight2>
        </Flex>*/}
      </Flex>

      <Flex jusifyContent={'center'} pb={2} flexWrap={'wrap'} width={'100%'}>
        {imgs.map((src, index) => {
          return <Collab key={index} imgSrc={src} />
        })}
      </Flex>

      {/*<Flex display={['none', 'none', 'flex']}>
        <Link style={{ textDecoration: 'none' }} to='/blog'>
          <TextWithClick color={'secondaryLight'} mt={6}>
            SE ALLA VÅRA PARTNERS
            <ArrowRight2
              style={{ marginBottom: '5px', marginLeft: '5px' }}
              size={'20px'}></ArrowRight2>
          </TextWithClick>
        </Link>
      </Flex>*/}
    </Flex>
  )
}
