import React from 'react'
import { Flex, Text } from '../../primitives'
import { Link } from 'gatsby'
import { TextWithClick } from '../text/TextWithHover'
import { ArrowRight2 } from 'styled-icons/icomoon/ArrowRight2'
import { TextWithEllipsis } from '../text/TextWithEllipsis'
import { ButtonPrimaryLight } from '../buttons/ButtonPrimaryLight'
import styled from 'styled-components'
import { Section } from './Section'
import { Image } from '../../shareable/Images/Image'

const FlexHover = styled(Flex)`
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.07);
  &:hover {
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.2);
  }
`
const BlogItem = ({ imgSrc, title, text, onNavigate }) => {
  return (
    <FlexHover
      onClick={() => {
        onNavigate()
      }}
      height={['450px', '450px', '450px']}
      style={{
        borderRadius: '10px'
      }}
      width={['300px', '350px', '320px']}
      flexDirection={'column'}
      mr={[0, 0, 2]}
      mb={[4, 4, 0]}
      ml={[0, 0, 2]}>
      <Image
        style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}
        height={'225px'}
        width={'100%'}
        src={imgSrc}
      />
      <Flex flexDirection={'column'} p={4} height={['225px', '225px', '225px']}>
        <Text color={'secondaryLight'} fontSize={2} mb={2}>
          ARTIKEL
        </Text>
        <Text
          style={{
            overflow: 'hidden'
          }}
          maxHeight={'50px'}
          mb={1}
          fontSize={5}
          fontWeight={500}>
          {title}
        </Text>
        <TextWithEllipsis
          height={'70px'}
          mb={3}
          fontSize={3}
          style={{ maxLines: 3, textOverflow: 'hidden' }}>
          {text}
        </TextWithEllipsis>
        <Flex flex={1} alignItems={'flex-end'}>
          <TextWithClick fontSize={2} color={'secondaryLight'}>
            LÄS HELA ARTIKELN
          </TextWithClick>
        </Flex>
      </Flex>
    </FlexHover>
  )
}

export const Blog = ({ blogs, navigate }) => {
  return (
    <Section>
      {/*<Flex
        width={['100%', '100%', 'initial']}
        jusifyContent={'space-between'}>
        <Text fontSize={[6, 6, 8]} mb={6} fontWeight={600}>
          Från bloggen
        </Text>

        <Flex display={['flex', 'flex', 'none']}>
          <ArrowRight2
            style={{ marginTop: '5px', marginLeft: '5px' }}
            size={'20px'}></ArrowRight2>
        </Flex>
      </Flex>*/}

      <Flex flexDirection={['column', 'column', 'row']}>
        {blogs && blogs[0] && (
          <BlogItem
            onNavigate={() => {
              navigate(blogs[0].url)
            }}
            key={blogs[0].url}
            title={blogs[0].title}
            text={blogs[0].description}
            imgSrc={blogs[0].imageFile.childImageSharp.fluid}
          />
        )}
        {blogs && blogs[1] && (
          <BlogItem
            onNavigate={() => {
              navigate(blogs[1].url)
            }}
            key={blogs[1].url}
            title={blogs[1].title}
            text={blogs[1].description}
            imgSrc={blogs[1].imageFile.childImageSharp.fluid}
          />
        )}
        {blogs && blogs[2] && (
          <BlogItem
            onNavigate={() => {
              navigate(blogs[2].url)
            }}
            key={blogs[2].url}
            title={blogs[2].title}
            text={blogs[2].description}
            imgSrc={blogs[2].imageFile.childImageSharp.fluid}
          />
        )}
      </Flex>

      <Flex display={['none', 'none', 'flex']}>
        <Link style={{ textDecoration: 'none' }} to='/blog'>
          <ButtonPrimaryLight color={'#636752'} mt={6}>
            TILL STORIES
            <ArrowRight2
              style={{ marginBottom: '5px', marginLeft: '5px' }}
              size={'20px'}
            />
          </ButtonPrimaryLight>
        </Link>
      </Flex>
    </Section>
  )
}
