import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Text } from '../../primitives'

export const TextWithEllipsis = styled(Text)`
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height:  ${(props) => props.height};
  line-height: 1.4;
  -webkit-line-clamp: ${(props) => props.lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

TextWithEllipsis.propTypes = {
  ...Text.propTypes,
  height: PropTypes.string,
  lines: PropTypes.number
}

TextWithEllipsis.defaultProps = {
  height: '63px',
  lines: 3,
  fontSize:"14px",
  ...Text.defaultProps
}


