import React from 'react'
import { Button, Flex, ImageContain, Text } from '../../primitives'
import image2 from '../../../images/whattogetproduct.png'
import { Check } from 'styled-icons/boxicons-regular/Check'
import { Image } from '../Images/Image'
import { conceptPrice, guidancePrice } from '../../../helpers/config'

const Item = ({ title, check, checkTwo }) => {
  return (
    <Flex
      width={'100%'}
      jusifyContent={'space-around'}
      height={'45px'}
      alignItems={'center'}>
      <Text flex={1} fontSize={3} color={'white'} fontWeight={500}>
        {title}
      </Text>

      <Flex flex={1}>
        <Check
          color={check ? 'white' : 'transparent'}
          size='25px'
          style={{
            marginBottom: '5px',
            marginRight: '5px',
            width: '100%'
          }}
        />
      </Flex>

      <Flex flex={1}>
        <Check
          color={checkTwo ? 'white' : 'transparent'}
          size='25px'
          style={{
            flex: 1,
            marginBottom: '5px',
            marginRight: '5px',
            width: '100%'
          }}
        />
      </Flex>
    </Flex>
  )
}

const MobileItem = ({ title, desc, list, price, onClick, m = 2 }) => {
  return (
    <Flex
      width={'300px'}
      pr={4}
      m={m}
      minHeight={'450px'}
      pl={4}
      flexDirection={'column'}
      bg={'primaryLight'}
      style={{ borderRadius: '5px' }}
      alignItems={'center'}>
      <Text color={'white'} fontWeight={500} fontSize={7} mt={4} mb={4}>
        {title}
      </Text>
      <Text color={'white'} textAlign={'center'} mb={4}>
        {desc}
      </Text>

      <Flex flexDirection={'column'} flex={1}>
        {list.map((i) => {
          return (
            <Flex mb={2}>
              <Check
                color={'white'}
                size='25px'
                style={{
                  marginBottom: '5px',
                  marginRight: '5px'
                }}
              />
              <Text flex={1} fontSize={4} color={'white'} fontWeight={500}>
                {i}
              </Text>
            </Flex>
          )
        })}
      </Flex>

      <Flex flexDirection={'column'} alignItems={'center'} mt={4} mb={4}>
        <Button mb={4} onClick={onClick}>
          KOM IGÅNG
        </Button>

        <Text color={'white'} fontWeight={500} fontSize={7} mb={2}>
          {price}
        </Text>
      </Flex>
    </Flex>
  )
}

export const WhatYouGet = ({ actionClick, img }) => {
  return (
    <>
      <Flex
        mt={4}
        mb={8}
        flexWrap={'wrap'}
        jusifyContent={'center'}
        display={['flex', 'flex', 'none']}>
        <MobileItem
          title={'Rådgivning'}
          desc={
            'Få snabba tips kring din inredning av en professionell inredare som tar fram en digital shoppinglista till dig.'
          }
          list={['30 min samtal med inredare per rum', 'Digital shoppinglista']}
          price={guidancePrice}
          onClick={actionClick}
        />

        <MobileItem
          title={'Koncept'}
          desc={
            'Få ett helhetskoncept kring ditt rum av en professionell\n' +
            ' inredare som arbetar fram känsla, stil och möblering anpassat\n' +
            ' efter dina behov och önskemål.'
          }
          list={[
            '30 min samtal med inredare per rum',
            'Digital shoppinglista',
            'Moodboard',
            'Planskiss',
            'Feedback genom hela processen',
            "Uppdatering av konceptet en vända'"
          ]}
          price={conceptPrice}
          onClick={actionClick}
        />
      </Flex>
      <Flex
        display={['none', 'none', 'flex']}
        width={'100%'}
        style={{ maxHeight: '700px' }}>
        <Flex jusifyContent={'center'} width={'50%'}>
          <Image src={img} />
        </Flex>
        <Flex flexDirection={['column', 'column', 'row']} width={'50%'}>
          <Flex
            pt={2}
            pb={4}
            flex={1}
            flexDirection={'column'}
            alignItems={'center'}
            jusifyContent={'center'}>
            <Text fontSize={[6, 6, 8]} mb={[4, 4, 2]} fontWeight={600}>
              Våra tjänster
            </Text>

            <Flex
              mt={2}
              width={'100%'}
              jusifyContent={'center'}
              display={['none', 'none', 'flex']}>
              <MobileItem
                title={'Rådgivning'}
                desc={
                  'Få snabba tips kring din inredning av en professionell inredare som tar fram en digital shoppinglista till dig.'
                }
                list={[
                  '30 min samtal med inredare per rum',
                  'Digital shoppinglista'
                ]}
                price={guidancePrice}
                onClick={actionClick}
              />

              <MobileItem
                title={'Koncept'}
                desc={
                  'Få ett helhetskoncept kring ditt rum av en professionell\n' +
                  ' inredare som arbetar fram känsla, stil och möblering anpassat\n' +
                  ' efter dina behov och önskemål.'
                }
                list={[
                  '30 min samtal med inredare per rum',
                  'Digital shoppinglista',
                  'Moodboard',
                  'Planskiss',
                  'Feedback genom hela processen',
                  "Uppdatering av konceptet en vända'"
                ]}
                price={conceptPrice}
                onClick={actionClick}
              />
            </Flex>
            <Flex
              mt={8}
              display={['flex', 'flex', 'none']}
              alignItems={'center'}
              jusifyContent={'center'}>
              <Flex jusifyContent={'center'} width={'100%'}>
                <ImageContain
                  width={['70%', '50%']}
                  height={'100px'}
                  src={image2}
                />
              </Flex>
            </Flex>
            {/* <Flex mt={4} alignItems={'center'} pr={4} pl={4}>
            <Text color={'white'} fontSize={[4, 6, 6]} mr={2}>
              Alla våra färgförslag är verifierade av
            </Text>
            <Image
              objectFit={'contain'}
              width={'100px'}
              height={'100px'}
              src={logo}
            />
          </Flex>*/}
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
