import React from 'react'
import { Flex, Text } from '../../primitives'
import { TextWithEllipsis } from '../text/TextWithEllipsis'
import '../../../css/arrow.css'
import { Section } from './Section'
const fakedata = [
  {
    imgSrc:
      'https://www.telegraph.co.uk/content/dam/fashion/2017/06/19/TELEMMGLPICT000131421802_trans_NvBQzQNjv4BqkUE_BTgBOQu3VWKvpDGX9fr7sARQy7EgBjwPUzvqL_M.jpeg?imwidth=450',
    color: '#798675',
    name: 'Mia S',
    text:
      'Jag behövde inte inredning till ett helt rum, så det var grymt att Hemset kunde hjälpa mig att få till det sista. Mycket trevlig och hjälpsam inredare! Jag har redan rekommendera tjänsten till mina vänner.'
  },
  {
    imgSrc:
      'https://www.hbo.com/content/dam/hbodata/series/game-of-thrones/character/s5/john-snow-1920.jpg/_jcr_content/renditions/cq5dam.web.1200.675.jpeg',
    color: '#798675',
    name: 'Josefin N',
    text:
      // eslint-disable-next-line
      'Även om vi själva gillar att inreda så hjälpte Hemsets inredare oss att hitta produkter i material och färger vi själva inte skulle ha tänkt på men som gav ett superfint resultat!   Vi kommer definitivt använda Hemset igen till andra rum i hemmet.'
  },
  {
    imgSrc: 'http://assets.viewers-guide.hbo.com/large59824b2079116@2x.jpg',
    color: '#798675',
    name: 'Johan L',
    text:
      'Hemset är verkligen prisvärt och supersmidigt. Det var så himla roligt att sätta upp alla saker och se hur rummet kom till liv. Inredaren fixade min smak och stil spot-on!'
  },
  {
    imgSrc:
      'https://media.comicbook.com/2019/04/ned-stark-game-of-thrones-1167089-1280x0.jpeg',
    color: '#798675',
    name: 'Simon L',
    text:
      'För mig var Hemsets tjänst perfekt. Jag visste hur jag ville att resultatet skulle bli men inte vägen dit. Av Hemset fick jag precis det jag sökte och följde förslaget till nästan 100%. Lägenheten ser fantastisk ut.'
  }
]

const CustomerItem = ({ name, text, color }) => {
  return (
    <Flex
      className={'arrow_box'}
      bg={'white'}
      height={['180px']}
      p={4}
      style={{
        boxShadow: ' 0px 1px 20px rgba(0, 0, 0, 0.07)',
        borderRadius: '10px'
      }}
      mb={[8, 8, 4]}
      mr={[2, 2, 0]}
      ml={[2, 2, 0]}
      width={['initial', 'initial', '420px']}>
      <Flex display={['none', 'none', 'flex']}>
        <Flex
          height={'35px'}
          width={'35px'}
          style={{ borderRadius: '50%', backgroundColor: color }}
        />
      </Flex>

      <Flex
        display={['flex', 'flex', 'none']}
        height={'35px'}
        width={'35px'}
        style={{
          position: 'absolute',
          top: '-20px',
          borderRadius: '50%',
          backgroundColor: color,
          border: '1px solid white'
        }}
      />

      <Flex flexDirection={'column'} ml={[0, 0, 2]}>
        <Text
          style={{ textTransform: 'capitalize' }}
          mb={[1, 1, 2]}
          fontSize={4}
          fontWeight={'bold'}>
          {name}
        </Text>
        <Flex width={['initial', 'initial', '320px']}>
          <TextWithEllipsis
            height={'110px'}
            fontSize={3}
            lines={6}
            style={{ maxLines: 3, textOverflow: 'hidden' }}>
            {text}
          </TextWithEllipsis>
        </Flex>
      </Flex>
    </Flex>
  )
}

export const Customer = () => {
  return (
    <Section bg={'grey'}>
      <Text fontSize={[6, 6, 8]} fontWeight={600} mb={[8, 8, 6]}>
        Vad säger våra kunder?
      </Text>

      <Flex
        jusifyContent={'space-between'}
        flexDirection={['column', 'column', 'row']}
        width={['100%', '100%', '850px']}
        style={{ flexWrap: 'wrap' }}>
        {fakedata.map((customer, index) => {
          return (
            <CustomerItem
              key={index}
              color={customer.color}
              name={customer.name}
              text={customer.text}
              imgSrc={customer.imgSrc}></CustomerItem>
          )
        })}
      </Flex>
    </Section>
  )
}
