import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts'
import { HeroFrontPage } from '../components/shareable/hero/HeroFrontPage'
import { WhatYouGet } from '../components/shareable/sections/WhatYouGet'
import { QuestionMobile } from '../components/shareable/sections/Question'
import { Blog } from '../components/shareable/sections/Blog'
import { Customer } from '../components/shareable/sections/Customer'
import { Collaborators } from '../components/shareable/sections/Collaborators'
import { Footer } from '../components/shareable/footer/Footer'
import { Section } from '../components/shareable/sections/Section'
import { Flex } from '../components/primitives'

export const GatsbyQuery = graphql`
  query {
    hemsetAPI {
      blogs(limit: 3) {
        _id
        createdAt
        updatedAt
        title
        description
        url
        metaTitle
        metaDesc
        imageFile {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        files {
          origName
          name
          url
          uploadUrl
        }
      }
    }
    heroImg: file(relativePath: { eq: "Hemset_Header.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroImgMobile: file(relativePath: { eq: "hemset_mobile_header.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whatYouGetImg: file(relativePath: { eq: "Inredare-2.1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    konst: file(relativePath: { eq: "konst.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    svenssons: file(relativePath: { eq: "Svenssons_temp_sv.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jotun: file(relativePath: { eq: "Jotun_std_110_RGB_72dpi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pegs: file(relativePath: { eq: "Prettygpegs.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pin: file(relativePath: { eq: "23pine logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    dearsam: file(relativePath: { eq: "logga-png-pos.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    reforma: file(relativePath: { eq: "reforma.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    ellos: file(relativePath: { eq: "ellos.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({ data, navigate, location }) => {
  const {
    hemsetAPI: { blogs }
  } = data

  const [modalIsOpen, setIsModalOpen] = useState(false)

  return (
    <Layout
      location={location}
      navigate={navigate}
      modelOpenProps={modalIsOpen}
      onCloseModal={() => {
        setIsModalOpen(false)
      }}
      headTitle={'Hemset.se | Det nya sättet att inreda'}
      metaDesc={
        'Spara tid & inred ditt hem online med Hemset inredningshjälp. Våra professionella inredare ger rådgivning & inredningskoncept mot en prisvärd fast avgift.'
      }>
      <HeroFrontPage
        headerSrc={data.heroImg.childImageSharp.fluid}
        headerSrcMobile={data.heroImgMobile.childImageSharp.fluid}
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />

      <QuestionMobile />

      <Collaborators
        imgs={[
          data.konst.childImageSharp.fluid,
          data.svenssons.childImageSharp.fluid,
          data.jotun.childImageSharp.fluid,
          data.pegs.childImageSharp.fluid,
          data.pin.childImageSharp.fluid,
          data.dearsam.childImageSharp.fluid,
          data.reforma.childImageSharp.fluid,
          data.ellos.childImageSharp.fluid
        ]}
      />

      <Section pt={[0, 0, 0]} pb={[0, 0, 0]}>
        <Flex flexDirection={['column', 'column', 'row']} width={'100%'}>
          <WhatYouGet
            img={data.whatYouGetImg.childImageSharp.fluid}
            logo={data.jotun.childImageSharp.fluid}
            actionClick={() => {
              setIsModalOpen(true)
            }}
          />
        </Flex>
      </Section>

      <Customer />

      <Blog blogs={blogs} navigate={navigate} />

      <Footer
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />
    </Layout>
  )
}
