import { Flex } from '../../primitives'
import React from 'react'

export const Section = ({
  children,
  bg,
  pr = [3, 3, 0],
  pl = [3, 3, 0],
  pb = [4, 5, 6],
  pt = [4, 5, 6]
}) => {
  return (
    <Flex
      pr={pr}
      pl={pl}
      pt={pt}
      bg={bg}
      pb={pb}
      flexDirection={'column'}
      alignItems={'center'}
      jusifyContent={'center'}>
      {children}
    </Flex>
  )
}
